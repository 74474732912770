import { Button, Col, Form, Input, InputNumber, Row, Select, Space, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { DateSelector, LayoutNav, UploadImage } from 'components/layout.component';
import { CKEditorComp } from 'components/promotion.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { PROMOTION_STATE, PROMOTION_TYPE } from 'enum/promotion';
import { REDENVELOPE_CYCLE, WHETHER } from 'enum/state';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions, selectFuzzyFilter, specialProviderName, timeL2S, verify } from "utils/common";

// 新增紅包優惠

const PageMain: React.FC = () => {
  const site = useSite();
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState<string>();
  const [isPopupValue, setIsPopupValue] = useState(true);
  const [categoryCodes, setCategoryCodes] = useState<any>([]);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  ]);

  const { data: category } = $get({
    url: 'api/category/list',
    params: {
      Status: 1,
    },
  });
  const { data: provider } = $get({
    url: 'api/bonus/luckymoney/provider/list',
    params: {
      CategoryCodes: form.getFieldValue('CategoryCodes')?.join(','),
    },
    allow: !!categoryCodes && categoryCodes.length > 0,
  });

  useEffect(() => {
    form.setFieldsValue({
      promotionType: PROMOTION_TYPE.redEnvelopePromotions,
      promotionName: '',
      popupDisplay: null,
      redirectToPromotionPage: null,
      period: REDENVELOPE_CYCLE.everyDay,
      rolloverRate: '',
      applicationMethod: 1,
      distributionMethod: 2,
      status: null,
      sort: null,
    })
    setImageData([])
  }, []);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/bonus/luckymoney/create',
      send: {
        Status: formData.status,
        Name: formData.promotionName,
        StartDate: timeL2S(date[0]),
        EndDate: timeL2S(date[1]),
        IsPopUp: formData.popupDisplay,
        IsRedirect: formData.redirectToPromotionPage,
        Period: formData.period,
        Content: editorContent,
        Photo: imageData[0] ? [imageData[0].url] : [],
        ValidBetTimes: Number(formData.rolloverRate),
        Categories: formData.CategoryCodes,
        ProviderIds: formData.ProviderIds,
        RewardQuantity: Number(formData.redenvelopeSets),
        RewardAmount: Number(formData.redenvelopeAmount),
        Position: Number(formData.sort),
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        navigate('/promotion');
      },
      resCode: RESPONSE_CODE_BONUS
    }, setLoading)
  }

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const typeChanged = (val: number) => {
    switch (val) {
      case PROMOTION_TYPE.otherPromotions:
        navigate('/promotion/add-other')
        break;
      case PROMOTION_TYPE.storePromotions:
        navigate('/promotion/add-store')
        break;
      case PROMOTION_TYPE.redEnvelopePromotions:
        navigate('/promotion/add-red-envelope')
        break;
    }
  }

  useEffect(() => {
    if (provider) {
      const providerIds = form.getFieldValue('ProviderIds');
      const currentProvider = provider?.Data.map((item: any) => item.Id);
      const filteredProviderIds = providerIds?.filter((item: any) => currentProvider?.includes(item));
      form.setFieldValue('ProviderIds', filteredProviderIds);
    }
  }, [categoryCodes, form, provider]);

  const handleCategoryCodesChanged = (value: any) => {
    setCategoryCodes(value);
    if (value?.length === 0) {
      form.setFieldValue('ProviderIds', []);
    };
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            {/* 類別與確認 */}
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Form.Item name="promotionType" className="w-14" label={i18n.t('promotionCategory')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select options={enumToOptions(PROMOTION_TYPE)} onChange={typeChanged} />
                  </Form.Item>
                </Col>
                <Col>
                  <Space size="middle">
                    <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="promotionName" label={i18n.t('promotionName')}
                rules={[{ required: true, message: `${i18n.t('required')}` },
                { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }]}>
                <Input className="w-14" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="popupDisplay" className="w-14" label={i18n.t('popupDisplay')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      onChange={e => {
                        setIsPopupValue(e === 0);
                        form.setFieldValue('redirectToPromotionPage', 0);
                      }}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="redirectToPromotionPage" className="w-14" label={i18n.t('redirectToPromotionPage')}
                    required rules={[{ required: !isPopupValue, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled={isPopupValue}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 週期 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="period" className="w-14" label={i18n.t('cycle')} required
                    rules={[{ required: true }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(REDENVELOPE_CYCLE)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 遊戲大類 / 遊戲商 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="CategoryCodes" className="w-14" label={
                    <div>
                      <div>{`${i18n.t('applicable')}${i18n.t('gameCategory')}`}</div>
                      <div className="color-03">{`(${i18n.t('selectableMultiple')})`}</div>
                    </div>
                  }>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={i18n.t('pleaseSelect')}
                      filterOption={selectFuzzyFilter}
                      onChange={handleCategoryCodesChanged}
                      options={
                        category?.Data.map((option: any) => ({
                          value: option.Code,
                          label: i18n.t(option.Name)
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name='ProviderIds' className="w-14" label={
                    <div>
                      <div>{`${i18n.t('applicable')}${i18n.t('gameProvider')}`}</div>
                      <div className="color-03">{`(${i18n.t('selectableMultiple')})`}</div>
                    </div>
                  }>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder={i18n.t('pleaseSelect')}
                      filterOption={selectFuzzyFilter}
                      disabled={!categoryCodes || categoryCodes.length < 1}
                      options={
                        provider?.Data?.map((option: any) => ({
                          value: option.Id,
                          label: `${specialProviderName(option.Name, site.data.SiteName, option.Code)}${i18n.t(option.CategoryCode)}`
                        }))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="redenvelopeSets" label={i18n.t('redenvelopeSets')}
                    rules={[
                      ...verify({ required: true, point: 7, message: i18n.t('invalidFormat') }),
                      {
                        validator: async (r, value) => {
                          if (Number(value) <= 0 && value) {
                            return Promise.reject(() => (
                              <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')} 1`}</p>
                            ));
                          }
                          if (Number(value) > 10000 && value) {
                            return Promise.reject(() => (
                              <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')} 10000`}</p>
                            ));
                          }
                        }
                      }
                    ]} required>
                    <Input className="w-14" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="redenvelopeAmount" label={i18n.t('redenvelopeAmount')}
                    rules={[
                      ...verify({ required: true, point: 7, message: i18n.t('invalidFormat') }),
                      {
                        validator: async (r, value) => {
                          if (Number(value) <= 0 && value) {
                            return Promise.reject(() => (
                              <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')} 1`}</p>
                            ));
                          }
                          if (Number(value) > 9999999 && value) {
                            return Promise.reject(() => (
                              <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')} 9999999`}</p>
                            ));
                          }
                        }
                      }
                    ]} required>
                    <Input className="w-14" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="rolloverRate" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                    <Input className="w-14" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="applicationMethod" className="w-14" label={i18n.t('applicationMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('autoApplication')}` },
                        { value: 1, label: `${i18n.t('memberManualApplication')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="distributionMethod" className="w-14" label={i18n.t('distributionMethod')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      disabled
                      placeholder={i18n.t('pleaseSelect')}
                      options={[
                        { value: 0, label: `${i18n.t('systemDistribution')}` },
                        { value: 1, label: `${i18n.t('manualDispatch')}` },
                        { value: 2, label: `${i18n.t('directClaim')}` }
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <Form.Item name="status" className="w-14" label={i18n.t('status')}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_STATE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="sort" label={i18n.t('sort')}  rules={[...verify({ point: 0 }), { max: 99, type: 'number', message: `${i18n.t('maximumValueIs99')}` }]}>
                    <InputNumber className="w-14" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="promotionTime" label={`${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`}
                    rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                    <DateSelector form={form} name="promotionTime" date={date} setDate={setDate} displayCom={['Picker']} 
                      rangeLimit={DATE_RANGE_LIMIT.future} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 上傳封面圖片 */}
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="Photo" label={i18n.t('image')} 
                    rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                    <UploadImage
                      name="Photo"
                      url={'/upload/images/public/bonus'}
                      imageData={imageData}
                      setImageData={setImageData}
                      form={form}
                      w={1920}
                      h={560}
                    />
                  </Form.Item>
                  <div className="color-03 size-12">
                    {i18n.t('onlyJpgOrPngFiles')}
                    <span className="require">{i18n.t('imageMobileVersionWidth1920pxHeight560px')}</span>
                    {i18n.t('allowedAndSizeNotExceed500kb')}
                  </div>
                </Col>
              </Row>
            </Col>
            {/* 內文編輯器 */}
            <Col span={24}>
              <Form.Item name="internalContent" label={i18n.t('internalContent')}
                rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} image />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content >
    </div >
  );
};

export default PageMain;